<template>
  <div>
    <v-form ref="form2" v-model="valid" lazy-validation>
      <v-dialog v-model="showForm" max-width="1000px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }} </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.code"
                    :rules="[(v) => !!v || 'Code obligatoire']"
                    label="Code"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.label"
                    label="Libelle"
                    :rules="[(v) => !!v || 'Libelle obligatoire']"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="4" md="4" v-if="editedItem.id < 0">
                  <selecttree
                    :items="list"
                    v-model="parent"
                    :value="parent"
                    :label="'Ajouter Dans'"
                    :oblig="true"
                    :item_disabled="'locked'"
                    :deletable_chips="false"
                    :readonly="!modify"
                    :select_type="'leaf'"
                    :selectable="false"
                    :dense="true"
                    :closeoncontentclick="true"
                    @change="parent_change"
                  >
                  </selecttree>
                </v-col>

                <v-col cols="12" sm="2" md="2" v-if="structtype == 's'">
                  <v-autocomplete
                    v-model="editedItem.type_id"
                    :items="types_list.filter((elm) => elm.hiden != true)"
                    item-text="label"
                    item-value="id"
                    :label="'Type Branche'"
                    dense
                    @change="type_change"
                    v-if="parent.length > 0 && editedItem.parent_type <= 1"
                    :readonly="!modify"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="10" md="10">
                  <v-textarea
                    outlined
                    dense
                    v-model="editedItem.description"
                    label="Description"
                    :readonly="!modify"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-checkbox
                    v-model="editedItem.distant"
                    label="Distant"
                    :readonly="!modify"
                    v-if="editedItem.type_id > 1 && editedItem.type_id < 4"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4" md="4" v-if="editedItem.type_id == 2">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    type="text"
                    label="Rack"
                    v-model="editedItem.rack"
                    :readonly="!modify"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4" v-if="editedItem.type_id == 2">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    type="text"
                    label="Box"
                    v-model="editedItem.box"
                    :readonly="!modify"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4" v-if="editedItem.type_id == 2">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    type="text"
                    label="Niveau"
                    v-model="editedItem.range"
                    :readonly="!modify"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    v-model="editedItem.resp_id"
                    :items="agents"
                    item-text="nom"
                    item-value="id"
                    :label="'Responsable'"
                    dense
                    :readonly="!modify"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="editedItem.type_id < 4 && editedItem.distant"
                >
                  <v-autocomplete
                    v-model="editedItem.departement_id"
                    :items="departements"
                    item-text="nom_departement"
                    item-value="id"
                    :rules="[(v) => !!v || 'Departement obligatoire']"
                    label="Departement"
                    dense
                    @change="dept_change"
                    :readonly="!modify"
                    :disabled="item.NbProduit > 0"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-if="editedItem.type_id < 4 && editedItem.distant"
                >
                  <v-autocomplete
                    v-model="editedItem.ville_id"
                    :items="villes"
                    item-text="nom_ville"
                    item-value="id"
                    label="Ville"
                    :rules="[(v) => !!v || 'Ville obligatoire']"
                    dense
                    :readonly="!modify"
                    :disabled="item.NbProduit > 0"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="8"
                  v-if="editedItem.type_id < 4 && editedItem.distant"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    type="text"
                    label="Adresse"
                    :rules="[(v) => !!v || 'Adresse obligatoire']"
                    v-model="editedItem.adress"
                    :readonly="!modify"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="editedItem.type_id < 4 && editedItem.distant"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.cp"
                    label="Code Postal"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="editedItem.type_id < 4 && editedItem.distant"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.gps"
                    label="Coordonnées GPS"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  md="3"
                  v-if="editedItem.type_id < 4 && editedItem.distant"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.email"
                    label="E-mail"
                    :rules="[
                      (v) =>
                        !v ||
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        'E-mail non valide',
                    ]"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="editedItem.type_id < 4 && editedItem.distant"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.phone"
                    label="Tel"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="editedItem.type_id < 4 && editedItem.distant"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.mobile"
                    label="Mobile"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="editedItem.type_id < 4 && editedItem.distant"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.fax"
                    label="Fax"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  md="2"
                  v-if="editedItem.type_id < 4 && editedItem.distant"
                >
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.disk"
                    label="Disque"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    autocomplete="off"
                    v-model="color"
                    hide-details
                    class="ma-0 pa-0"
                    solo
                    label="Couleur"
                    clearable
                    :readonly="!modify"
                  >
                    <template v-slot:append>
                      <v-menu
                        v-model="menu"
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                              v-model="color"
                              flat
                              dense
                              :disabled="!modify"
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!valid"
              @click="save"
              v-if="modify"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click="close"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_STRUCTURE from "../graphql/Structure/CREATE_STRUCTURE.gql";
import UPDATE_STRUCTURE from "../graphql/Structure/UPDATE_STRUCTURE.gql";

export default {
  name: "structure",
  components: {
    selecttree: () => import("./SelectTree.vue"),
  },
  props: {
    item: Object,
    items: Array,
    showForm: Boolean,
    list: Array,
    agents: Array,
    departements: Array,
    modify: Boolean,
    structtype: String,
  },

  data: () => ({
    menu: false,
    valid: true,
    editedItem: {},
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    parent: [],
    villes: [],
    types_list: [
      { id: 0, label: "Racine", hiden: true },
      { id: 1, label: "Site", hiden: true },
      { id: 2, label: "Depot", hiden: true },
      { id: 3, label: "Atelier" },
      { id: 4, label: "Structure" },
    ],
    color: "",
  }),

  computed: {
    swatchStyle() {
      return {
        backgroundColor: this.color,
        border: this.color ? "" : "solid",
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: this.menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    formTitle() {
      return this.editedItem.id == -1
        ? "Nouvelle Structure"
        : "Modifier Structure";
    },
  },

  watch: {},

  created() {},
  mounted() {
    if (this.item) {
      this.editedItem = Object.assign({}, this.item);
      this.$refs.form2.resetValidation();
      if (this.item.color) this.color = this.item.color;
      if (this.editedItem.fk_parent != 0) {
        this.parent.push(this.editedItem["fk_parent"]);
      }
      if (this.editedItem.departement_id) {
        this.villes = this.select_ville(this.editedItem.departement_id);
      }
      if (this.editedItem.id < 0) {
        if (this.editedItem.parent_type > 0) {
          this.editedItem.type_id = this.editedItem.parent_type;
        }
        if (this.structtype == "d") this.editedItem.type_id = 2;
        this.types_list[2].hiden = this.structtype == "s";
        this.types_list[1].hiden = this.editedItem.parent_type != "0";
        this.type_change();
      }
    }
  },
  methods: {
    type_change() {
      this.color =
        this.editedItem.type_id == 1
          ? "#332CE7FF"
          : this.editedItem.type_id == 2
          ? "#1DCC34FF"
          : this.editedItem.type_id == 3
          ? "#FF9600FF"
          : "yellow";
    },
    select_ville(departement_id) {
      let list = [];
      let i = this.departements.findIndex((h) => h.id === departement_id);
      if (i >= 0) list = this.departements[i].villes;
      return list;
    },
    dept_change() {
      this.editedItem.ville_id = "";
      this.villes = this.select_ville(this.editedItem.departement_id);
    },
    parent_change(item) {
      this.parent = item;
      this.editedItem.parent_type = item[0].type_id;
      this.types_list[1].hiden = this.editedItem.parent_type != "0";
      if (this.editedItem.parent_type > 0) {
        this.editedItem.type_id = this.editedItem.parent_type;
        this.type_change();
      }
    },
    close() {
      this.$emit("close", this.editedItem);
    },
    save() {
      if (this.$refs.form2.validate()) {
        this.editedItem.fk_parent =
          this.parent.length > 0 ? this.parent.toString() : "0";
        this.editedItem.color = this.color;
        this.editedItem.type = this.types_list.find(
          (elm) => elm.id == this.editedItem.type_id
        ).label;
        if (this.editedItem.resp_id)
          this.editedItem.responsable = this.agents.find(
            (elm) => elm.id == this.editedItem.resp_id
          ).nom;
        if (this.editedItem.id > -1) {
          this.$apollo
            .mutate({
              mutation: UPDATE_STRUCTURE,
              variables: {
                structure: {
                  id: this.editedItem.id,
                  label: this.editedItem.label,
                  fk_parent: this.editedItem.fk_parent,
                  code: this.editedItem.code,
                  description: this.editedItem.description,
                  distant: this.editedItem.distant ? 1 : 0,
                  resp_id: this.editedItem.resp_id,
                  color: this.editedItem.color,
                  type_id: this.editedItem.type_id,
                  cp: this.editedItem.cp,
                  ville_id: this.editedItem.ville_id,
                  adress: this.editedItem.adress,
                  email: this.editedItem.email,
                  phone: this.editedItem.phone,
                  mobile: this.editedItem.mobile,
                  fax: this.editedItem.fax,
                  gps: this.editedItem.gps,
                  rack: this.editedItem.rack,
                  box: this.editedItem.box,
                  range: this.editedItem.range,
                  disk: this.editedItem.disk,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then(() => {
              this.editedItem.add = false;
              this.items.splice(this.item.index, 1, this.editedItem);
              this.$emit("change", this.editedItem);
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        } else {
          this.$apollo
            .mutate({
              mutation: CREATE_STRUCTURE,
              variables: {
                structure: {
                  label: this.editedItem.label,
                  fk_parent: this.editedItem.fk_parent,
                  code: this.editedItem.code,
                  description: this.editedItem.description,
                  distant: this.editedItem.distant ? 1 : 0,
                  resp_id: this.editedItem.resp_id,
                  color: this.editedItem.color,
                  type_id: this.editedItem.type_id,
                  cp: this.editedItem.cp,
                  ville_id: this.editedItem.ville_id,
                  adress: this.editedItem.adress,
                  email: this.editedItem.email,
                  phone: this.editedItem.phone,
                  mobile: this.editedItem.mobile,
                  fax: this.editedItem.fax,
                  gps: this.editedItem.gps,
                  rack: this.editedItem.rack,
                  box: this.editedItem.box,
                  range: this.editedItem.range,
                  disk: this.editedItem.disk,
                  create_uid: this.$store.state.me.id,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then((data) => {
              this.editedItem.id = data.data.createStructure.id;
              this.editedItem.add = true;
              this.$emit("add", this.editedItem);
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        }
      }
    },
  },
};
</script>
